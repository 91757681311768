<template>
  <div>
    <div class="align-items-center mb-1 d-flex flex-row justify-content-between">
      <div
        id="summary-title-order-amount-payfast"
        data-testid="summary-title-order-amount-payfast"
        class="font-14 text-placeholder"
      >
        {{ $t('payment.airWallex.labelOrderAmount') }}:
      </div>
      <strong
        id="summary-local-bank-order-amount"
        data-testid="summary-payfast-order-amount"
      >
        {{ localStringFormat(amount) }} USD
      </strong>
    </div>

    <div class="align-items-center mb-1 d-flex flex-row justify-content-between">
      <div
        id="summany-local-bank-title-payment-method"
        data-testid="summany-local-bank-title-payment-method"
        class="font-14 text-placeholder"
      >
        {{ $t('addFundPage.creditCardPayment.textPaymentMethod') }}:
      </div>

      <strong
        id="summary-local-bank-method"
        data-testid="summary-local-bank-method"
        class="font-14 text-primary"
      >
        <img
          :src="logoLocalBank"
          height="16px"
          alt="PayFast"
          style="margin-right: 5px"
        >
        Local bank
      </strong>
    </div>

    <div
      v-if="serviceCharge > 0"
      class="d-flex align-items-center flex-row justify-content-between mb-1"
    >
      <div
        id="summary-local-bank-title-service-fee"
        data-testid="summary-local-bank-title-service-fee"
        class="font-14 text-placeholder"
      >
        {{ $t('payment.balanceTransactionsTable.textServiceCharge') }}
      </div>
      <strong
        id="summary-local-bank-current-service-charge"
        data-testid="summary-local-bank-current-service-charge"
        class="text-primary"
      >{{ serviceCharge }} USD</strong>
    </div>

    <div
      v-if="localBankFee > 0"
      class="d-flex align-items-center justify-content-between mb-1"
    >
      <div
        id="summary-title-local-bank-fee"
        data-testid="summary-title-local-bank-fee"
        class="font-14 text-placeholder"
      >
        {{ $t('payment.paypal.textPaymentFee', { payment: 'Local bank' }) }}:
      </div>
      <div>
        <div
          class="d-flex align-items-center"
        >
          <strong
            id="summary-current-local-bank-fee"
            data-testid="summary-current-local-bank-fee"
            class="text-primary"
          >{{ localBankFee }} USD</strong>
        </div>
      </div>
    </div>

    <div
      v-if="tax > 0"
      class="align-items-center d-flex flex-row justify-content-between mb-1"
    >
      <div
        id="summary-local-bank-title-tax-fee"
        data-testid="summary-local-bank-title-tax-fee"
        class="font-14 text-placeholder"
      >
        {{ $t('payment.balanceTransactionsTable.textTax') }}:
      </div>
      <strong
        id="summary-local-bank-current-tax-fee"
        data-testid="summary-local-bank-current-tax-fee"
        class="text-primary"
      >{{ tax }} USD</strong>
    </div>

    <div
      v-if="amountTip > 0"
      class="d-flex align-items-center justify-content-between"
    >
      <div
        id="summary-ideal-title-tip-amount"
        data-testid="summary-ideal-title-tip-amount"
        class="font-14 text-placeholder"
      >
        {{ $t('payment.balanceTransactionsTable.textTip') }}:
      </div>
      <strong
        id="summary-ideal-current-tip-amount"
        data-testid="summary-ideal-current-tip-amount"
        class="text-primary"
      >{{ amountTip }} USD</strong>
    </div>

    <hr class="hr-custom">

    <div class="d-flex align-items-center justify-content-between">
      <div class="font-14 text-placeholder text-left">
        {{ $t('addFundPage.creditCardPayment.textTotalAmount') }} = {{ $t('balance.textAmount') }}
        {{ serviceCharge > 0 ? `+ ${$t('payment.balanceTransactionsTable.textServiceCharge')}` : '' }}
        {{ tax > 0 ? ` + ${$t('payment.balanceTransactionsTable.textTax')}` : '' }}
        {{ localBankFee > 0 ? `+ ${$t('payment.paypal.textPaymentFee', {payment:"Local bank"})}` : '' }}
        {{ amountTip > 0 ? ` + ${$t('payment.balanceTransactionsTable.textTip')}` : '' }}
      </div>
    </div>

    <div class="d-flex align-items-center justify-content-between mb-1 mt-2">
      <div class="font-24 text-placeholder">
        {{ $t('SMB.payment.totalAmount') }}
      </div>
      <strong
        v-if="amount"
        id="total-amount"
        class="font-24 total-amount"
      >
        {{ localStringFormat(totalAmount) }} USD
      </strong>
      <strong v-else>
        --
      </strong>
    </div>

    <div class="d-flex align-items-center justify-content-between mb-3">
      <div class="font-14 text-placeholder">
        {{ $t('SMB.payment.textAmountConvert') }}
      </div>
      <strong
        v-if="amount"
        id="amount"
      >
        {{ localStringFormat(convertUSDtoVND(totalAmount)) }} VNĐ
      </strong>
      <strong v-else>
        --
      </strong>
    </div>
  </div>
</template>

<script>
import paymentMixin from '@/mixins/paymentMixin'
import generalConfigsMixin from '@/mixins/generalConfigsMixin'
import numberFormatMixin from '@/mixins/numberFormatMixin'

// plugins
import { createNamespacedHelpers } from 'vuex'

const { mapGetters } = createNamespacedHelpers('payment')
const { mapGetters: mapGettersAuth } = createNamespacedHelpers('auth')

export default {

  mixins: [paymentMixin, generalConfigsMixin, numberFormatMixin],

  data() {
    return {
      amount: Number(this.$route.query.amount) || 0,
      bankTransferAmount: this.$route.query.bankTransferAmount || '',
      paymentMethod: this.$route.query.paymentMethod || '',
      historyTransaction: {},
      amountTip: 0,
      totalAmount: 0,
      localBankFee: 0,
      tax: 0,
      serviceCharge: 0,
    }
  },

  computed: {
    ...mapGettersAuth(['user', 'generalConfigs']),
    ...mapGetters(['listBusinessAccountHistoryLimit']),

    logoLocalBank() {
      // eslint-disable-next-line global-require
      return require('@/assets/images/pages/payment/ic-local-bank.png')
    },

    isInTrial() {
      return this.user?.isInTrial
    },

    amountValidate() {
      return !!(this.amount >= this.minTopUp && this.amount <= this.maxAmount)
    },

    maxAmount() {
      return 100000
    },
  },

  watch: {
    listBusinessAccountHistoryLimit: {
      handler(transactions) {
        if (transactions.paging.total > 0) {
          this.historyTransaction = transactions.content.find(transaction => transaction.paymentMethod === 'localBank')
          this.amountTip = this.historyTransaction?.payload?.tipAMAmount || 0
          this.totalAmount = this.historyTransaction?.payload?.bankTransferAmount
          this.serviceCharge = this.amountFormat(this.historyTransaction?.payload?.serviceCharge) || 0
          this.tax = this.amountFormat(this.historyTransaction?.payload?.taxCharge) || 0
          this.localBankFee = this.amountFormat(this.historyTransaction?.payload?.paymentGateCharge) || 0
        }
      },
      deep: true,
      immediate: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.summary-container {
  padding: 20px;
  background-color: #F5F5F7;
  // border: 1px solid rgba(22, 33, 62, 0.1);
  border-radius: 16px;

  .title-summary{
    margin-bottom: 21px;
  }
}
.transfer {
  max-width: 125px;
}

.total-amount{
  color: #00A9DE;
}
</style>
