<template>
  <div class="summary-container">
    <h4 class="title-summary">
      Summary
    </h4>
    <p
      v-if="topUp"
      class="font-14"
    >
      Payment information details.
    </p>
    <hr class="hr-custom">
    <div class="d-flex align-items-center justify-content-between mb-1">
      <div class="font-14 text-placeholder">
        Amount
      </div>
      <strong v-if="validateAmount">
        {{ localStringFormat(amount) }} USD
      </strong>
      <strong v-else>
        --
      </strong>
    </div>
    <div class="d-flex align-items-center justify-content-between mb-1">
      <div class="font-14 text-placeholder">
        Service Charge ({{ percentServiceCharge * 100 }}%):
      </div>
      <div v-if="validateAmount">
        {{ localStringFormat(percentServiceCharge * amount) }} USD
      </div>
      <strong v-else>
        --
      </strong>
    </div>
    <div class="d-flex align-items-center justify-content-between mb-1">
      <div class="font-14 text-placeholder">
        Payment method
      </div>
      <div v-if="validateAmount">
        <img
          :src="getBrandIcon(`BUSD`)"
          width="18px"
          alt="coin"
        >
        BUSD
      </div>
      <strong v-else>
        --
      </strong>
    </div>
    <div class="d-flex align-items-center justify-content-between mb-1">
      <div class="font-14 text-placeholder">
        Transfer network
      </div>
      <div>
        <div
          v-if="validateAmount"
          class="d-flex align-items-center font-12 text-right"
        >
          Binance Smart
          <br>
          Chain BSC (BNB)
        </div>
        <strong v-else>
          --
        </strong>
      </div>
    </div>

    <div class="d-flex align-items-center justify-content-between">
      <div class="font-14 text-placeholder">
        Tax ({{ percentTaxFee * 100 }}%):
      </div>
      <div>
        <div
          v-if="validateAmount"
          class="d-flex align-items-center"
        >
          {{ localStringFormat(percentTaxFee * amount) }} USD
        </div>
        <strong v-else>
          --
        </strong>
      </div>
    </div>
    <hr class="hr-custom">
    <div
      v-if="!topUp"
      class="d-flex align-items-center justify-content-between"
    >
      <div class="font-14 text-placeholder">
        Total
      </div>
      <strong v-if="validateAmount">
        {{ currencyFormat(total) }}
      </strong>
      <strong v-else>
        --
      </strong>
    </div>
    <div v-else>
      <p class="font-14 font-medium mb-0">
        Total = Amount + Tax + Service Charge({{ percentServiceCharge * 100 }}%)
      </p>
      <strong class="font-20">{{ currencyFormat(total) }}</strong>
    </div>
  </div>
</template>

<script>
import numberFormatMixin from '@/mixins/numberFormatMixin'
import paymentMixin from '@/mixins/paymentMixin'
// plugins
import { createNamespacedHelpers } from 'vuex'

const { mapGetters: mapGettersAuth } = createNamespacedHelpers('auth')

export default {
  mixins: [numberFormatMixin, paymentMixin],

  props: {
    amount: {
      type: Number,
      required: true,
    },

    topUp: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    ...mapGettersAuth(['generalConfigs']),

    percentServiceCharge() {
      return this.user?.data?.plans?.transferFee?.BUSD || 0
    },

    percentTaxFee() {
      return this.taxRate
    },

    validateAmount() {
      return this.amount < 100001
    },

    total() {
      return this.amount + ((this.percentServiceCharge + this.percentTaxFee) * this.amount)
    },
  },
}
</script>

<style lang="scss" scoped>
.summary-container {
  padding: 20px;

  border: 1px solid rgba(22, 33, 62, 0.1);
  border-radius: 16px;

  .title-summary{
    margin-bottom: 21px;
  }
}
</style>
