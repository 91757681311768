<template>
  <div v-if="user.data.localUserId">
    <b-container v-if="paymentMethod === 'payoneer'">
      <b-row
        align-content="center"
        align-h="center"
        :cols="hasStepGuide ? 6 : 3"
        class="mt-2 py-3"
      >
        <b-col
          cols="12"
          :md="hasStepGuide ? 11 : 7"
          :lg="hasStepGuide ? 9 : 5"
          class="text-center"
        >
          <div class="header px-2 pb-1">
            <img
              alt="success"
              :src="require('@/assets/images/pages/payment/ic-payment-success-2.svg')"
            >
            <h3 class="my-1">
              {{ $t('addFundPage.creditCardPayment.textTopupSuccess') }}
            </h3>
            <p>{{ $t('addFundPage.creditCardPayment.subTitlePaymentSuccess') }}</p>
          </div>

          <div class="summary p-2 card">
            <h4
              id="title-summary-po"
              data-testid="title-summary-po"
              class="text-left mb-2"
            >
              {{ $t('addFundPage.creditCardPayment.textSummary') }}
            </h4>
            <div class="align-items-center mb-1 d-flex flex-row flex-wrap justify-content-between">
              <div
                id="title-client-po"
                data-testid="title-client-po"
                class="font-14 text-placeholder"
              >
                {{ $t('addFundPage.creditCardPayment.textClient') }}:
              </div>
              <strong
                id="client-name-po"
                data-testid="client-name-po"
              >
                {{ fullName }}
              </strong>
            </div>
            <div class="align-items-center mb-1 d-flex flex-row justify-content-between">
              <div
                id="summary-title-order-amount-po"
                data-testid="summary-title-order-amount-po"
                class="font-14 text-placeholder"
              >
                {{ $t('payment.airWallex.labelOrderAmount') }}:
              </div>
              <strong
                id="summary-currency-amount-po"
                data-testid="summary-currency-amount-po"
                class="order-amount"
              >
                {{ currencyFormat(amount) }} USD
              </strong>
            </div>
            <div class="align-items-center mb-1 d-flex flex-row justify-content-between">
              <div
                id="summary-title-po-method"
                data-testid="summary-title-po-method"
                class="font-14 text-placeholder"
              >
                {{ $t('addFundPage.creditCardPayment.textPaymentMethod') }}:
              </div>

              <strong
                id="summary-po-method"
                data-testid="summary-po-method"
                class="font-14 text-primary"
              >
                <img
                  :src="payoneerLogo"
                  height="16px"
                  alt="payoneer"
                  style="margin-right: 5px"
                >
                Payoneer
              </strong>
            </div>

            <div
              v-if="percentFee"
              class="align-items-center d-flex flex-row justify-content-between mb-1"
            >
              <div
                id="summary-title-service-fee"
                data-testid="summary-title-service-fee"
                class="font-14 text-placeholder"
              >
                {{ $t('payment.balanceTransactionsTable.textServiceCharge') }}:
              </div>
              <strong
                id="summary-current-service-fee"
                data-testid="summary-current-service-fee"
                class="text-primary"
              >{{ currencyFormat(serviceCharge) }} USD</strong>
            </div>

            <div
              v-if="POMethodFee"
              class="d-flex align-items-center justify-content-between mb-1"
            >
              <div
                id="summary-title-po-fee"
                data-testid="summary-title-po-fee"
                class="font-14 text-placeholder"
              >
                {{ $t('payment.paypal.textPaymentFee', {payment:"Payoneer"}) }}
              </div>
              <div>
                <div
                  class="d-flex align-items-center"
                >
                  <strong
                    id="summary-current-po-fee"
                    data-testid="summary-current-po-fee"
                    class="text-primary"
                  >{{ currencyFormat(payoneerFee) }} USD</strong>
                </div>
              </div>
            </div>

            <div
              v-if="taxRate"
              class="align-items-center d-flex flex-row justify-content-between mb-1"
            >
              <div
                id="summary-title-tax-po-fee"
                data-testid="summary-title-tax-po-fee"
                class="font-14 text-placeholder"
              >
                {{ $t('payment.balanceTransactionsTable.textTax') }}:
              </div>
              <strong
                id="summary-current-tax-po-fee"
                data-testid="summary-current-tax-po-fee"
                class="text-primary"
              >{{ currencyFormat(tax) }} USD</strong>
            </div>

            <div
              v-if="tipAmount"
              class="align-items-center d-flex flex-row justify-content-between"
            >
              <div
                id="summary-title-tip-fee-po"
                data-testid="summary-title-tip-fee-po"
                class="font-14 text-placeholder"
              >
                {{ $t('payment.balanceTransactionsTable.textTip') }}:
              </div>
              <strong
                id="summary-current-tip-amount-po"
                data-testid="summary-current-tip-amount-po"
                class="text-primary"
              >{{ currencyFormat(tipAmountTxt) }} USD</strong>
            </div>

            <hr class="hr-custom">

            <div class="align-items-center">
              <div class="font-14 text-placeholder text-left">
                {{ $t('addFundPage.creditCardPayment.textTotalAmount') }} = {{ $t('balance.textAmount') }}
                {{ percentFee ? ` + ${$t('payment.balanceTransactionsTable.textServiceCharge')}` : '' }}
                {{ taxRate ? ` + ${$t('payment.balanceTransactionsTable.textTax')}` : '' }}
                {{ POMethodFee ? ` + ${$t('payment.paypal.textPaymentFee', {payment: "Payoneer"})}` : '' }}
                {{ tipAmount > 0 ? ` + ${$t('payment.balanceTransactionsTable.textTip')}` : '' }}
              </div>
            </div>
            <h3
              id="current-total-amount-po"
              data-testid="current-total-amount-po"
              class="mt-2 text-left"
            >
              {{ currencyFormat(bankTransferAmount) }} USD
            </h3>
          </div>

          <div
            class="action mt-2 d-flex flex-column flex-sm-row justify-content-between align-items-center action-wrap"
          >
            <btn-loading
              id="btn-summary-recharge-po"
              data-testid="btn-summary-recharge-po"
              variant-convert="outline-dark"
              class="btn-sm-block btn-recharge mr-1"
              pill
              @click="$router.push('/payment/add-fund?tab=payoneer')"
            >
              {{ $t('payment.textRecharge') }}
            </btn-loading>
            <btn-loading
              id="btn-show-me-ad-account-po"
              data-testid="btn-show-me-ad-account-po"
              variant-convert="btn-submit"
              class="w-100"
              pill
              @click="$router.history.push('/my-ads-account')"
            >
              {{ $t('addFundPage.creditCardPayment.textShowMeAdAccount') }}
            </btn-loading>
          </div>
          <p
            v-if="isOnlyEcomdyPlatform"
            class="text-center mt-1"
          >*{{ $t('addFundPage.creditCardPayment.textYouCanDownload') }}
            <span
              class="cursor-pointer text-purple underline"
              @click="$router.push('/payment?tab=payment_history')"
            >
              {{ $t('pricingPage.purchaseSuccessView.textHere') }}
            </span>
          </p>
        </b-col>
      </b-row>
    </b-container>
    <b-container v-else-if="paymentMethod === 'airWallexAuto' && !hasStepGuide">
      <b-row
        align-content="center"
        align-h="center"
        :cols="hasStepGuide ? 6 : 3"
        class="mt-2 py-3"
      >
        <b-col
          cols="12"
          :md="hasStepGuide ? 11 : 7"
          :lg="hasStepGuide ? 9 : 5"
          class="text-center"
        >
          <div class="header px-2 pb-1">
            <img
              alt="success"
              :src="require('@/assets/images/pages/payment/ic-payment-success-2.svg')"
            >
            <h3 class="my-1">
              {{ $t('addFundPage.creditCardPayment.textTopupSuccess') }}
            </h3>
            <p>{{ $t('addFundPage.creditCardPayment.subTitlePaymentSuccess') }}</p>
          </div>

          <div class="summary p-2 card">
            <h4
              id="title-summary-airwallex"
              data-testid="title-summary-airwallex"
              class="text-left mb-2"
            >
              {{ $t('addFundPage.creditCardPayment.textSummary') }}
            </h4>
            <div class="align-items-center mb-1 d-flex flex-row flex-wrap justify-content-between">
              <div
                id="summary-title-client-airwallex"
                data-testid="summary-title-client-airwallex"
                class="font-14 text-placeholder"
              >
                {{ $t('addFundPage.creditCardPayment.textClient') }}:
              </div>
              <strong
                id="summary-airwallex-client"
                data-testid="summary-airwallex-client"
              >
                {{ fullName }}
              </strong>
            </div>
            <div class="align-items-center mb-1 d-flex flex-row justify-content-between">
              <div
                id="summary-title-order-amount-airwallex"
                data-testid="summary-title-order-amount-airwallex"
                class="font-14 text-placeholder"
              >
                {{ $t('payment.airWallex.labelOrderAmount') }}:
              </div>
              <strong
                id="summary-airwallex-order-amount"
                data-testid="summary-airwallex-order-amount"
              >
                {{ currencyFormat(amount) }} USD
              </strong>
            </div>
            <div class="align-items-center mb-1 d-flex flex-row justify-content-between">
              <div
                id="summany-airwallex-title-payment-method"
                data-testid="summany-airwallex-title-payment-method"
                class="font-14 text-placeholder"
              >
                {{ $t('addFundPage.creditCardPayment.textPaymentMethod') }}:
              </div>

              <strong
                id="summary-airwallex-method"
                data-testid="summary-airwallex-method"
                class="font-14 text-primary"
              >
                <img
                  :src="airwallexLogo"
                  height="16px"
                  alt="Airwallex"
                  style="margin-right: 5px"
                >
                Airwallex
              </strong>
            </div>

            <div
              v-if="percentFeeAirwallexAuto"
              class="d-flex align-items-center flex-row justify-content-between mb-1"
            >
              <div
                id="summary-airwallex-title-service-fee"
                data-testid="summary-airwallex-title-service-fee"
                class="font-14 text-placeholder"
              >
                {{ $t('payment.balanceTransactionsTable.textServiceCharge') }}:
              </div>
              <strong
                id="summary-airwallex-current-service-charge"
                data-testid="summary-airwallex-current-service-charge"
                class="text-primary"
              >{{ currencyFormat(serviceChargeAirwallexAuto) }} USD</strong>
            </div>

            <div
              v-if="methodAirwallexFee"
              class="d-flex align-items-center justify-content-between mb-1"
            >
              <div
                id="summary-title-airwallex-fee"
                data-testid="summary-title-airwallex-fee"
                class="font-14 text-placeholder"
              >
                {{ $t('payment.paypal.textPaymentFee', { payment: 'Airwallex' }) }}:
              </div>
              <div>
                <div
                  class="d-flex align-items-center"
                >
                  <strong
                    id="summary-current-airwallex-fee"
                    data-testid="summary-current-airwallex-fee"
                    class="text-primary"
                  >{{ currencyFormat(airwallexFee) }} USD</strong>
                </div>
              </div>
            </div>

            <div
              v-if="taxRate"
              class="align-items-center d-flex flex-row justify-content-between mb-1"
            >
              <div
                id="summary-airwallex-title-tax-fee"
                data-testid="summary-airwallex-title-tax-fee"
                class="font-14 text-placeholder"
              >
                {{ $t('payment.balanceTransactionsTable.textTax') }}:
              </div>
              <strong
                id="summary-airwallex-current-tax-fee"
                data-testid="summary-airwallex-current-tax-fee"
                class="text-primary"
              >{{ currencyFormat(tax) }} USD</strong>
            </div>

            <div
              v-if="tipAmount"
              class="d-flex align-items-center justify-content-between"
            >
              <div
                id="summary-airwallex-title-tip-amount"
                data-testid="summary-airwallex-title-tip-amount"
                class="font-14 text-placeholder"
              >
                {{ $t('payment.balanceTransactionsTable.textTip') }}:
              </div>
              <strong
                id="summary-airwallex-current-tip-amount"
                data-testid="summary-airwallex-current-tip-amount"
                class="text-primary"
              >{{ currencyFormat(tipAmountTxt) }} USD</strong>
            </div>

            <hr class="hr-custom">

            <div class="d-flex align-items-center justify-content-between">
              <div class="font-14 text-placeholder text-left">
                {{ $t('addFundPage.creditCardPayment.textTotalAmount') }} = {{ $t('balance.textAmount') }}
                {{ percentFeeAirwallexAuto ? ` + ${$t('payment.balanceTransactionsTable.textServiceCharge')}` : '' }}
                {{ taxRate ? ` + ${$t('payment.balanceTransactionsTable.textTax')}` : '' }}
                {{ methodAirwallexFee ? ` + ${$t('payment.paypal.textPaymentFee', {payment: "Airwallex"})}` : '' }}
                {{ tipAmount > 0 ? ` + ${$t('payment.balanceTransactionsTable.textTip')}` : '' }}
              </div>
            </div>
            <h3
              id="summary-airwallex-current-total-amount"
              data-testid="summary-airwallex-current-total-amount"
              class="mt-2 text-left"
            >
              {{ currencyFormat(bankTransferAmount) }} USD
            </h3>
          </div>

          <div
            class="action mt-2 d-flex flex-column flex-sm-row justify-content-between align-items-center action-wrap"
          >
            <btn-loading
              id="btn-summary-airwallex-recharge"
              data-testid="btn-summary-airwallex-recharge"
              variant-convert="outline-dark"
              class="btn-sm-block btn-recharge mr-1"
              pill
              @click="$router.push('/payment/add-fund?tab=airwallex')"
            >
              {{ $t('payment.textRecharge') }}
            </btn-loading>
            <btn-loading
              id="btn-summary-airwallex-show-me-ad"
              data-testid="btn-summary-airwallex-show-me-ad"
              variant-convert="btn-submit"
              class="w-100"
              pill
              @click="$router.history.push('/my-ads-account')"
            >
              {{ $t('addFundPage.creditCardPayment.textShowMeAdAccount') }}
            </btn-loading>
          </div>
          <p
            v-if="isOnlyEcomdyPlatform"
            class="text-center mt-1"
          >*{{ $t('addFundPage.creditCardPayment.textYouCanDownload') }}
            <span
              class="text-purple cursor-pointer underline"
              @click="$router.push('/payment?tab=payment_history')"
            >
              {{ $t('addFundPage.textHere') }}
            </span>
          </p>
        </b-col>
      </b-row>
    </b-container>
    <b-container v-else-if="paymentMethod === 'payfast' && !hasStepGuide">
      <b-row
        align-content="center"
        align-h="center"
        :cols="hasStepGuide ? 6 : 3"
        class="mt-2 py-3"
      >
        <b-col
          cols="12"
          :md="hasStepGuide ? 11 : 7"
          :lg="hasStepGuide ? 9 : 5"
          class="text-center"
        >
          <div class="header px-2 pb-1">
            <img
              alt="success"
              :src="require('@/assets/images/pages/payment/ic-payment-success-2.svg')"
            >
            <h3 class="my-1">
              {{ $t('addFundPage.creditCardPayment.textTopupSuccess') }}
            </h3>
            <p>{{ $t('addFundPage.creditCardPayment.subTitlePaymentSuccess') }}</p>
          </div>

          <div class="summary p-2 card">
            <h4
              id="title-summary-payfast"
              data-testid="title-summary-payfast"
              class="text-left mb-2"
            >
              {{ $t('addFundPage.creditCardPayment.textSummary') }}
            </h4>
            <div class="align-items-center mb-1 d-flex flex-row flex-wrap justify-content-between">
              <div
                id="summary-title-client-payfast"
                data-testid="summary-title-client-payfast"
                class="font-14 text-placeholder"
              >
                {{ $t('addFundPage.creditCardPayment.textClient') }}:
              </div>
              <strong
                id="summary-payfast-client"
                data-testid="summary-payfast-client"
              >
                {{ fullName }}
              </strong>
            </div>

            <PayFastTopUpSuccess />
          </div>

          <div
            class="action mt-2 d-flex flex-column flex-sm-row justify-content-between align-items-center action-wrap"
          >
            <btn-loading
              id="btn-summary-payfast-recharge"
              data-testid="btn-summary-payfast-recharge"
              variant-convert="outline-dark"
              class="btn-sm-block btn-recharge mr-1"
              pill
              @click="$router.push('/payment/add-fund?tab=payfast')"
            >
              {{ $t('payment.textRecharge') }}
            </btn-loading>
            <btn-loading
              id="btn-summary-payfast-show-me-ad"
              data-testid="btn-summary-payfast-show-me-ad"
              variant-convert="btn-submit"
              class="w-100"
              pill
              @click="$router.history.push('/my-ads-account')"
            >
              {{ $t('addFundPage.creditCardPayment.textShowMeAdAccount') }}
            </btn-loading>
          </div>
          <p
            v-if="isOnlyEcomdyPlatform"
            class="text-center mt-1"
          >*{{ $t('addFundPage.creditCardPayment.textYouCanDownload') }}
            <span
              class="text-purple cursor-pointer underline"
              @click="$router.push('/payment?tab=payment_history')"
            >
              {{ $t('addFundPage.textHere') }}
            </span>
          </p>
        </b-col>
      </b-row>
    </b-container>
    <b-container v-else-if="paymentMethod === 'localBank' && !hasStepGuide">
      <b-row
        align-content="center"
        align-h="center"
        :cols="hasStepGuide ? 6 : 3"
        class="mt-2 py-3"
      >
        <b-col
          cols="12"
          :md="hasStepGuide ? 11 : 7"
          :lg="hasStepGuide ? 9 : 5"
          class="text-center"
        >
          <div class="header px-2 pb-1">
            <img
              alt="success"
              :src="require('@/assets/images/pages/payment/ic-payment-success-2.svg')"
            >
            <h3 class="my-1">
              {{ $t('addFundPage.creditCardPayment.textTopupSuccess') }}
            </h3>
            <p>{{ $t('addFundPage.creditCardPayment.subTitlePaymentSuccess') }}</p>
          </div>

          <div class="summary p-2 card">
            <h4
              id="title-summary-local-bank"
              data-testid="title-summary-local-bank"
              class="text-left mb-2"
            >
              {{ $t('addFundPage.creditCardPayment.textSummary') }}
            </h4>
            <div class="align-items-center mb-1 d-flex flex-row flex-wrap justify-content-between">
              <div
                id="summary-title-client-local-bank"
                data-testid="summary-title-client-local-bank"
                class="font-14 text-placeholder"
              >
                {{ $t('addFundPage.creditCardPayment.textClient') }}:
              </div>
              <strong
                id="summary-local-bank-client"
                data-testid="summary-local-bank-client"
              >
                {{ fullName }}
              </strong>
            </div>

            <LocalBankToUpSuccess />
          </div>

          <div
            class="action mt-2 d-flex flex-column flex-sm-row justify-content-between align-items-center action-wrap"
          >
            <btn-loading
              id="btn-summary-local-bank-recharge"
              data-testid="btn-summary-local-bank-recharge"
              variant-convert="outline-dark"
              class="btn-sm-block btn-recharge mr-1"
              pill
              @click="$router.push('/payment/add-fund?tab=local-bank')"
            >
              {{ $t('payment.textRecharge') }}
            </btn-loading>
            <btn-loading
              id="btn-summary-local-bank-show-me-ad"
              data-testid="btn-summary-local-bank-show-me-ad"
              variant-convert="btn-submit"
              class="w-100"
              pill
              @click="$router.history.push('/my-ads-account')"
            >
              {{ $t('addFundPage.creditCardPayment.textShowMeAdAccount') }}
            </btn-loading>
          </div>
          <p
            v-if="isOnlyEcomdyPlatform"
            class="text-center mt-1"
          >*{{ $t('addFundPage.creditCardPayment.textYouCanDownload') }}
            <span
              class="text-purple cursor-pointer underline"
              @click="$router.push('/payment?tab=payment_history')"
            >
              {{ $t('addFundPage.textHere') }}
            </span>
          </p>
        </b-col>
      </b-row>
    </b-container>
    <b-container v-else-if="paymentMethod === 'ideal' && !hasStepGuide">
      <b-row
        align-content="center"
        align-h="center"
        :cols="hasStepGuide ? 6 : 3"
        class="mt-2 py-3"
      >
        <b-col
          cols="12"
          :md="hasStepGuide ? 11 : 7"
          :lg="hasStepGuide ? 9 : 5"
          class="text-center"
        >
          <div class="header px-2 pb-1">
            <img
              alt="success"
              :src="require('@/assets/images/pages/payment/ic-payment-success-2.svg')"
            >
            <h3 class="my-1">
              {{ $t('addFundPage.creditCardPayment.textTopupSuccess') }}
            </h3>
            <p>{{ $t('addFundPage.creditCardPayment.subTitlePaymentSuccess') }}</p>
          </div>

          <div class="summary p-2 card">
            <h4
              id="title-summary-ideal"
              data-testid="title-summary-ideal"
              class="text-left mb-2"
            >
              {{ $t('addFundPage.creditCardPayment.textSummary') }}
            </h4>
            <div class="align-items-center mb-1 d-flex flex-row flex-wrap justify-content-between">
              <div
                id="summary-title-client-ideal"
                data-testid="summary-title-client-ideal"
                class="font-14 text-placeholder"
              >
                {{ $t('addFundPage.creditCardPayment.textClient') }}:
              </div>
              <strong
                id="summary-ideal-client"
                data-testid="summary-ideal-client"
              >
                {{ fullName }}
              </strong>
            </div>

            <ideal-to-up-success />
          </div>

          <div
            class="action mt-2 d-flex flex-column flex-sm-row justify-content-between align-items-center action-wrap"
          >
            <btn-loading
              id="btn-summary-ideal-recharge"
              data-testid="btn-summary-ideal-recharge"
              variant-convert="outline-dark"
              class="btn-sm-block btn-recharge mr-1"
              pill
              @click="$router.push('/payment/add-fund?tab=ideal')"
            >
              {{ $t('payment.textRecharge') }}
            </btn-loading>
            <btn-loading
              id="btn-summary-ideal-show-me-ad"
              data-testid="btn-summary-ideal-show-me-ad"
              variant-convert="btn-submit"
              class="w-100"
              pill
              @click="$router.history.push('/my-ads-account')"
            >
              {{ $t('addFundPage.creditCardPayment.textShowMeAdAccount') }}
            </btn-loading>
          </div>
          <p
            v-if="isOnlyEcomdyPlatform"
            class="text-center mt-1"
          >*{{ $t('addFundPage.creditCardPayment.textYouCanDownload') }}
            <span
              class="text-purple cursor-pointer underline"
              @click="$router.push('/payment?tab=payment_history')"
            >
              {{ $t('addFundPage.textHere') }}
            </span>
          </p>
        </b-col>
      </b-row>
    </b-container>
    <b-container v-else-if="paymentMethod === 'bancontact' && !hasStepGuide">
      <b-row
        align-content="center"
        align-h="center"
        :cols="hasStepGuide ? 6 : 3"
        class="mt-2 py-3"
      >
        <b-col
          cols="12"
          :md="hasStepGuide ? 11 : 7"
          :lg="hasStepGuide ? 9 : 5"
          class="text-center"
        >
          <div class="header px-2 pb-1">
            <img
              alt="success"
              :src="require('@/assets/images/pages/payment/ic-payment-success-2.svg')"
            >
            <h3 class="my-1">
              {{ $t('addFundPage.creditCardPayment.textTopupSuccess') }}
            </h3>
            <p>{{ $t('addFundPage.creditCardPayment.subTitlePaymentSuccess') }}</p>
          </div>

          <div class="summary p-2 card">
            <h4
              id="title-summary-bancontact"
              data-testid="title-summary-bancontact"
              class="text-left mb-2"
            >
              {{ $t('addFundPage.creditCardPayment.textSummary') }}
            </h4>
            <div class="align-items-center mb-1 d-flex flex-row flex-wrap justify-content-between">
              <div
                id="summary-title-client-bancontact"
                data-testid="summary-title-client-bancontact"
                class="font-14 text-placeholder"
              >
                {{ $t('addFundPage.creditCardPayment.textClient') }}:
              </div>
              <strong
                id="summary-bancontact-client"
                data-testid="summary-bancontact-client"
              >
                {{ fullName }}
              </strong>
            </div>

            <bancontact-to-up-success />
          </div>

          <div
            class="action mt-2 d-flex flex-column flex-sm-row justify-content-between align-items-center action-wrap"
          >
            <btn-loading
              id="btn-summary-bancontact-recharge"
              data-testid="btn-summary-bancontact-recharge"
              variant-convert="outline-dark"
              class="btn-sm-block btn-recharge mr-1"
              pill
              @click="$router.push('/payment/add-fund?tab=bancontact')"
            >
              {{ $t('payment.textRecharge') }}
            </btn-loading>
            <btn-loading
              id="btn-summary-bancontact-show-me-ad"
              data-testid="btn-summary-bancontact-show-me-ad"
              variant-convert="btn-submit"
              class="w-100"
              pill
              @click="$router.history.push('/my-ads-account')"
            >
              {{ $t('addFundPage.creditCardPayment.textShowMeAdAccount') }}
            </btn-loading>
          </div>
          <p
            v-if="isOnlyEcomdyPlatform"
            class="text-center mt-1"
          >*{{ $t('addFundPage.creditCardPayment.textYouCanDownload') }}
            <span
              class="text-purple cursor-pointer underline"
              @click="$router.push('/payment?tab=payment_history')"
            >
              {{ $t('addFundPage.textHere') }}
            </span>
          </p>
        </b-col>
      </b-row>
    </b-container>
    <div
      v-else-if="paymentMethod === 'BUSD'"
      class="container-box py-3"
    >
      <div class="header px-2 pb-1 text-center">
        <img
          alt="success"
          :src="require('@/assets/images/pages/payment/ic-payment-success-2.svg')"
        >
        <h3 class="my-1">
          Topup Success!
        </h3>
        <p>Thanks you for your purchase. Please check your balance again.</p>
      </div>
      <div class="summary">
        <summary-amount
          :amount="parseFloat(amount)"
          :top-up="true"
        />
      </div>
      <div class="action mt-2 d-flex justify-content-between">
        <!-- <b-button
          class="font-14"
          variant="outline-dark"
          pill
          @click="$router.push('/payment/add-fund?tab=busd')"
        >
          Recharge
        </b-button> -->

        <btn-loading
          class="font-14 btn-success w-100 py-1"
          variant-convert="btn-submit"
          pill
          @click="$router.history.push('/my-ads-account')"
        >
          {{ $t('addFundPage.creditCardPayment.textShowMeAdAccount') }}
        </btn-loading>
      </div>
    </div>

    <FirstTopUpSuccessModal v-if="isPancakeUser && pancakeUserStep" />
  </div>
</template>

<script>
import {
  BContainer, BRow, BCol,
} from 'bootstrap-vue'
import { createNamespacedHelpers } from 'vuex'
import numberFormatMixin from '@/mixins/numberFormatMixin'
import generalConfigsMixin from '@/mixins/generalConfigsMixin'
import gaTrackingMixin from '@/mixins/gaTrackingMixin'
import _get from 'lodash/get'
import paymentMixin from '@/mixins/paymentMixin'
import stepGuideMixin from '@/mixins/stepGuideMixin'
import { toastification } from '@core/mixins/toast'
import envMixin from '@/mixins/envMixin'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import FirstTopUpSuccessModal from '@/views/payment/components/FirstTopUpSuccessModal.vue'
import authMixin from '@/mixins/authMixin'
import LocalBankToUpSuccess from '@/views/payment/components/LocalBank/LocalBankToUpSuccess.vue'
import IdealToUpSuccess from '@/views/payment/components/iDEAL/IdealToUpSuccess.vue'
import BancontactToUpSuccess from '@/views/payment/components/Bancontact/BancontactToUpSuccess.vue'
import SummaryAmount from './components/BUSDCard/Summary.vue'
import PayFastTopUpSuccess from './components/PayFast/PayFastToUpSuccess.vue'

const { mapGetters: mapGettersAuth } = createNamespacedHelpers('auth')

export default {
  components: {
    BancontactToUpSuccess,
    IdealToUpSuccess,
    LocalBankToUpSuccess,
    FirstTopUpSuccessModal,
    BtnLoading,
    BContainer,
    BRow,
    BCol,
    SummaryAmount,
    PayFastTopUpSuccess,
  },
  mixins: [numberFormatMixin, generalConfigsMixin, paymentMixin, stepGuideMixin, toastification, envMixin, gaTrackingMixin, authMixin],

  data() {
    return {
      amount: this.$route.query.amount || '',
      bankTransferAmount: this.$route.query.bankTransferAmount || '',
      paymentMethod: this.$route.query.paymentMethod || '',
      listPM: ['payoneer', 'airWallexAuto', 'payfast', 'localBank', 'ideal', 'bancontact'],
      historyTransaction: {},
      pancakeUserStep: localStorage.getItem('pancakeUserStep') === '3',
    }
  },

  computed: {
    ...mapGettersAuth(['user', 'loading', 'generalConfigs', 'status']),

    fullName() {
      const fullName = _get(this.user, ['data', 'fullName'], '')
      const email = _get(this.user, ['data', 'email'], '')
      return fullName || email
    },
    payoneerLogo() {
      // eslint-disable-next-line global-require
      return require('@/assets/images/pages/payment/payoneer.svg')
    },
    airwallexLogo() {
      // eslint-disable-next-line global-require
      return require('@/assets/images/pages/payment/ic-airwallex.svg')
    },
    percentFee() {
      return this.user?.data?.plans?.transferFee?.payoneer || 0
    },
    percentFeeAirwallexAuto() {
      return this.user?.data?.plans?.transferFee?.airWallex || 0
    },

    serviceCharge() {
      return this.amountFormat(this.percentFee * this.amount)
        .toLocaleString('en-US')
    },
    serviceChargeAirwallexAuto() {
      return this.amountFormat(this.percentFeeAirwallexAuto * this.amount)
        .toLocaleString('en-US')
    },
    tax() {
      return this.amountFormat(this.taxRate * this.amount)
        .toLocaleString('en-US')
    },

    POMethodFee() {
      return this.generalConfigs?.paymentGateFee?.payoneerGate || 0
    },

    payoneerFee() {
      return this.amountFormat(this.POMethodFee * this.amount)
        .toLocaleString('en-US') || 0
    },

    methodAirwallexFee() {
      return this.generalConfigs?.paymentGateFee?.airWallexAutoGate || 0
    },

    airwallexFee() {
      return this.amountFormat(this.methodAirwallexFee * this.amount)
        .toLocaleString('en-US') || 0
    },

    tipAmount() {
      return this.historyTransaction?.payload?.tipAMAmount || null
    },

    tipAmountTxt() {
      return this.amountFormat(this.tipAmount)
        .toLocaleString('en-US')
    },

  },

  watch: {
    listBusinessAccountHistoryLimit: {
      handler(transactions) {
        if (transactions.paging.total > 0) {
          this.historyTransaction = transactions.content.find(transaction => transaction.paymentMethod === this.paymentMethod)
        }
      },
      deep: true,
      immediate: true,
    },
  },

  created() {
    const { amount } = this.$route.query
    const { fullPath } = this.$route

    if (this.isOnlyEcomdyPlatform) {
      this.gaTrackingUserAddFundSuccess(amount, this.paymentMethod) // GA Tracking
    }

    const checkUrl = fullPath.toLowerCase()
      .includes('/payment/success')

    if (Number.isNaN(parseFloat(amount, 10)) || (this.paymentMethod !== 'BUSD' && !this.listPM.includes(this.paymentMethod))) {
      this.$router.push('/payment/add-fund')
    }

    if (checkUrl && this.hasStepGuide) {
      if (this.isOnlyEcomdyPlatform) {
        this.gaTrackingUserStep(this.setupGuideStep) // GA Tracking
      }
      this.$router.push({ name: 'my-ads-account' })
    } else if (checkUrl) {
      this.toastSuccess(this.$t('payment.toastTopUpSuccess'))
    }
  },
}
</script>

<style lang="scss" scoped>
.container-box {
  max-width: 340px;
  margin: 0 auto;
}

.summary {
  background-color: #fff;
  border-radius: var(--border-radius-base);
  padding: 24px 17px;
}

.btn-recharge{
  font-size: 14px;
  padding: 11px 20px;
  width: 150px;
}
</style>
