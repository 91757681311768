<template>
  <div>
    <div class="align-items-center mb-1 d-flex flex-row justify-content-between">
      <div
        id="summary-title-order-amount-bancontact"
        data-testid="summary-title-order-amount-bancontact"
        class="font-14 text-placeholder"
      >
        {{ $t('payment.airWallex.labelOrderAmount') }}:
      </div>
      <strong
        id="summary-bancontact-order-amount"
        data-testid="summary-bancontact-order-amount"
      >
        {{ amount }} USD
      </strong>
    </div>

    <div class="align-items-center mb-1 d-flex flex-row justify-content-between">
      <div
        id="summany-bancontact-title-payment-method"
        data-testid="summany-bancontact-title-payment-method"
        class="font-14 text-placeholder"
      >
        {{ $t('addFundPage.creditCardPayment.textPaymentMethod') }}:
      </div>

      <strong
        id="summary-bancontact-method"
        data-testid="summary-bancontact-method"
        class="font-14 text-primary"
      >
        <img
          :src="bancontactLogo"
          height="16px"
          alt="Bancontact"
          style="margin-right: 5px"
        >
        Bancontact
      </strong>
    </div>

    <div class="align-items-center mb-1 d-flex flex-row justify-content-between">
      <div
        id="summany-bancontact-title-account-holder-name"
        data-testid="summany-bancontact-title-account-holder-name"
        class="font-14 text-placeholder"
      >
        {{ $t('setupCost.accountHolderName') }}:
      </div>

      <strong
        id="summary-bancontact-account-holder-name"
        data-testid="summary-bancontact-account-holder-name"
        class="font-14 text-primary"
      >
        {{ accountHolderName }}
      </strong>
    </div>

    <div class="align-items-center mb-1 d-flex flex-row justify-content-between">
      <div
        id="summany-bancontact-title-verified-name"
        data-testid="summany-bancontact-title-verified-name"
        class="font-14 text-placeholder"
      >
        {{ $t('setupCost.verifiedName', {method: 'Bancontact'}) }}:
      </div>

      <strong
        id="summary-bancontact-verified-name"
        data-testid="summary-bancontact-verified-name"
        class="font-14 text-primary"
      >
        {{ verifiedName }}
      </strong>
    </div>

    <div
      v-if="serviceCharge"
      class="d-flex align-items-center flex-row justify-content-between mb-1"
    >
      <div
        id="summary-bancontact-title-service-fee"
        data-testid="summary-bancontact-title-service-fee"
        class="font-14 text-placeholder"
      >
        {{ $t('payment.balanceTransactionsTable.textServiceCharge') }}:
      </div>
      <strong
        id="summary-bancontact-current-service-charge"
        data-testid="summary-bancontact-current-service-charge"
        class="text-primary"
      >{{ serviceCharge }} USD</strong>
    </div>

    <div
      v-if="bancontactFee"
      class="d-flex align-items-center justify-content-between mb-1"
    >
      <div
        id="title-bancontact-fee"
        data-testid="title-bancontact-fee"
        class="font-14 text-placeholder"
      >
        {{ $t('payment.paypal.textPaymentFee', {payment: "Bancontact"}) }}:
      </div>
      <div>
        <div
          v-if="amountValidate"
          class="d-flex align-items-center"
        >
          <strong
            id="current-bancontact-fee"
            data-testid="current-bancontact-fee"
            class="text-primary"
          >{{ bancontactFee }} USD</strong>
        </div>
        <strong v-else>
          --
        </strong>
      </div>
    </div>

    <div
      v-if="tax"
      class="d-flex align-items-center justify-content-between mb-1"
    >
      <div
        id="title-tax-fee-bancontact"
        data-testid="title-tax-fee-bancontact"
        class="font-14 text-placeholder"
      >
        {{ $t('payment.balanceTransactionsTable.textTax') }}:
      </div>
      <div>
        <div
          v-if="amountValidate"
          class="d-flex align-items-center"
        >
          <strong
            id="current-tax-fee-bancontact"
            data-testid="current-tax-fee-bancontact"
            class="text-primary"
          >{{ tax }} USD</strong>
        </div>
        <strong v-else>
          --
        </strong>
      </div>
    </div>

    <div
      v-if="amountTip > 0"
      class="d-flex align-items-center justify-content-between"
    >
      <div
        id="summary-bancontact-title-tip-amount"
        data-testid="summary-bancontact-title-tip-amount"
        class="font-14 text-placeholder"
      >
        {{ $t('payment.balanceTransactionsTable.textTip') }}:
      </div>
      <strong
        id="summary-bancontact-current-tip-amount"
        data-testid="summary-bancontact-current-tip-amount"
        class="text-primary"
      >{{ amountTip }} USD</strong>
    </div>

    <hr class="hr-custom">

    <div class="d-flex align-items-center justify-content-between mb-3">
      <div class="font-24 text-placeholder">
        {{ $t('addFundPage.creditCardPayment.textTotalAmount') }}
      </div>
      <strong
        v-if="amountValidate"
        id="amount"
        class="font-24 total-amount"
      >
        {{ totalAmount.toLocaleString('en-US') }} USD
      </strong>
      <strong v-else>
        --
      </strong>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-underscore-dangle,camelcase */
import paymentMixin from '@/mixins/paymentMixin'
import generalConfigsMixin from '@/mixins/generalConfigsMixin'
import numberFormatMixin from '@/mixins/numberFormatMixin'

// plugins
import { createNamespacedHelpers } from 'vuex'

const { mapGetters } = createNamespacedHelpers('payment')
const { mapGetters: mapGettersAuth } = createNamespacedHelpers('auth')

export default {

  mixins: [paymentMixin, generalConfigsMixin, numberFormatMixin],

  props: {
    listTransaction: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      amount: Number(this.$route.query.amount) || '',
      bankTransferAmount: this.$route.query.bankTransferAmount || '',
      paymentMethod: this.$route.query.paymentMethod || '',
      transactionId: this.$route.query.transactionId || '',

      amountTip: 0,
      totalAmount: 0,
      bancontactFee: 0,
      tax: 0,
      serviceCharge: 0,
      accountHolderName: '',
      verifiedName: '',
    }
  },

  computed: {
    ...mapGetters(['listBusinessAccountHistoryLimit']),
    ...mapGettersAuth(['user', 'generalConfigs']),

    amountValidate() {
      return !!(this.amount >= this.minTopUp && this.amount <= this.maxAmount)
    },

    maxAmount() {
      return 100000
    },

    bancontactLogo() {
      // eslint-disable-next-line global-require
      return require('@/assets/images/pages/payment/ic-bancontact.svg')
    },
  },
  watch: {
    listBusinessAccountHistoryLimit: {
      handler(transactions) {
        if (transactions.paging.total > 0) {
          this.historyTransaction = transactions.content.find(transaction => transaction._id === this.transactionId)

          this.amountTip = this.historyTransaction?.payload?.tipAMAmount || 0
          this.serviceCharge = this.amountFormat(this.historyTransaction?.payload?.serviceCharge) || 0
          this.tax = this.amountFormat(this.historyTransaction?.payload?.taxCharge) || 0
          this.bancontactFee = this.amountFormat(this.historyTransaction?.payload?.paymentGateCharge) || 0
          this.accountHolderName = this.historyTransaction?.payload?.accountHolderName
          this.verifiedName = this.historyTransaction?.payload?.payerName

          this.totalAmount = this.historyTransaction?.payload?.bankTransferAmount
        }
      },
      deep: true,
      immediate: true,
    },
  },

}
</script>

<style lang="scss" scoped>
.summary-container {
  padding: 20px;
  background-color: #F5F5F7;
  // border: 1px solid rgba(22, 33, 62, 0.1);
  border-radius: 16px;

  .title-summary {
    margin-bottom: 21px;
  }
}

.transfer {
  max-width: 125px;
}

.total-amount {
  color: #00A9DE;
}
</style>
